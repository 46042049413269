<template>
  <div class="mx-5">
    <v-col
      class="rounded-tr-circle rounded-tl-xxl rounded-br-xxl rounded-bl-xxl my-3"
      cols="12"
      :style="{ backgroundColor: $vuetify.theme.dark ? 'var(--dark-bg-home)' : 'var(--light-bg-home)' }"
      style="z-index: 1; backdrop-filter: blur(5px); -webkit-backdrop-filter: blur(5px)"
    >
      <v-row class="px-3">
        <v-col cols="8" class="mt-4">
          <div>
            <h6 v-if="prayerSchedule" class="text-caption white--text">
              {{ prayerSchedule.tanggal }}
            </h6>
            <h6 v-else class="text-caption white--text">Memuat...</h6>
          </div>
          <div class="my-3">
            <h2 class="white--text">
              <span class="text-h2 font-weight-black">{{ currentHourMinute }}</span>
              <span>{{ currentSecond }}</span>
            </h2>
          </div>
          <div>
            <h6 class="text-caption white--text"><v-icon class="text-caption" color="red">mdi-map-marker</v-icon> {{ selectedCityName }}</h6>
          </div>
        </v-col>
        <v-col cols="4" class="align">
          <v-row>
            <v-col cols="12">
              <div class="d-flex justify-center">
                <div class="rounded-circle d-flex align-center justify-center" style="margin-top: -20px">
                  <img src="../../images/image3.png" alt="img-akr" height="170" width="170" />
                </div>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-divider class="dotted-divider my-3"></v-divider>
      <v-row>
        <v-col cols="12" class="pa-3 d-flex justify-space-around text-center">
          <div v-for="prayer in prayerTimes" :key="prayer.name">
            <h6 class="yellow--text darken-2">{{ prayer.name }}</h6>
            <v-icon class="my-1" color="yellow darken-2">{{ prayer.icon }}</v-icon>
            <h6 class="yellow--text darken-2">{{ prayer.time }}</h6>
          </div>
        </v-col>
      </v-row>
      <v-divider class="dotted-divider my-3"></v-divider>
      <v-row>
        <v-col cols="12">
          <v-autocomplete class="text-caption pt-3" dense autocomplete="off" v-model="selectedCity" :items="cities" item-text="lokasi" item-value="id" label="Pilih Wilayah" outlined @change="getPrayerSchedule"></v-autocomplete>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      loading: false,
      selectedCity: "1107", // Default kota
      cities: [],
      prayerSchedule: null,
      currentHourMinute: "",
      currentSecond: "",
      prayerTimes: [],
      currentDate: new Date().toISOString().split("T")[0], // Gunakan tanggal hari ini
    };
  },
  computed: {
    selectedCityName() {
      const city = this.cities.find((city) => city.id === this.selectedCity);
      return city ? city.lokasi : "Memuat...";
    },
  },
  methods: {
    async getCities() {
      try {
        const response = await axios.get("https://api.myquran.com/v2/sholat/kota/semua");
        this.cities = response.data.data.sort((a, b) => a.lokasi.localeCompare(b.lokasi));
      } catch (error) {
        console.error("Gagal mengambil daftar kota:", error);
      }
    },
    async getPrayerSchedule() {
      if (!this.selectedCity) return;
      const today = new Date(); // Gunakan tanggal hari ini
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const date = String(today.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${date}`;
      const storageKey = `prayerSchedule_${this.selectedCity}`;
      const storageDateKey = `prayerSchedule_date_${this.selectedCity}`;

      // Cek apakah data di localStorage sudah kadaluarsa
      const storedDate = localStorage.getItem(storageDateKey);
      if (storedDate !== formattedDate) {
        console.log("Tanggal berubah, menghapus data lama...");
        localStorage.removeItem(storageKey);
        localStorage.removeItem(storageDateKey);
      }

      // Cek apakah ada data di localStorage setelah pengecekan tanggal
      const storedData = localStorage.getItem(storageKey);
      if (storedData && storedDate === formattedDate) {
        console.log("Menggunakan data dari localStorage");
        this.prayerSchedule = JSON.parse(storedData);
        this.updatePrayerTimes();
        return;
      }

      try {
        console.log("Mengambil data dari API...");
        const response = await axios.get(`https://api.myquran.com/v2/sholat/jadwal/${this.selectedCity}/${year}/${month}/${date}`);
        this.prayerSchedule = response.data.data.jadwal;

        // Simpan ke localStorage dengan tanggal
        localStorage.setItem(storageKey, JSON.stringify(this.prayerSchedule));
        localStorage.setItem(storageDateKey, formattedDate);

        this.updatePrayerTimes();
      } catch (error) {
        console.error("Gagal mengambil jadwal sholat:", error);
        alert("Gagal mengambil jadwal sholat. Pastikan koneksi internet tersedia.");
      }
    },
    updatePrayerTimes() {
      if (!this.prayerSchedule) return;
      this.prayerTimes = [
        { name: "Imsak", time: this.prayerSchedule.imsak, icon: "mdi-weather-partly-cloudy" },
        { name: "Subuh", time: this.prayerSchedule.subuh, icon: "mdi-weather-partly-cloudy" },
        { name: "Dhuha", time: this.prayerSchedule.dhuha, icon: "mdi-weather-partly-cloudy" },
        { name: "Dzuhur", time: this.prayerSchedule.dzuhur, icon: "mdi-weather-sunny" },
        { name: "Ashar", time: this.prayerSchedule.ashar, icon: "mdi-weather-sunset" },
        { name: "Maghrib", time: this.prayerSchedule.maghrib, icon: "mdi-weather-moonset" },
        { name: "Isya", time: this.prayerSchedule.isya, icon: "mdi-moon-waxing-crescent" },
      ];
    },
    updateCurrentTime() {
      setInterval(() => {
        const now = new Date();
        this.currentHourMinute = now.toLocaleTimeString("id-ID", { hour: "2-digit", minute: "2-digit" });
        this.currentSecond = `:${now.getSeconds().toString().padStart(2, "0")}`;

        const newDate = now.toISOString().split("T")[0];
        if (this.currentDate !== newDate) {
          console.log("Tanggal berubah, mengambil jadwal baru...");
          this.currentDate = newDate;
          this.getPrayerSchedule();
        }
      }, 1000);
    },
  },
  mounted() {
    this.getCities();
    this.getPrayerSchedule();
    this.updateCurrentTime();
  },
  watch: {
    selectedCity() {
      this.getPrayerSchedule();
    },
  },
};
</script>

<style scoped>
.v-card {
  max-width: 400px;
  margin: auto;
}
::v-deep(.v-input__control) {
  color: white !important;
  border-color: white !important;
}
::v-deep(.v-label) {
  color: white !important;
}
::v-deep(input) {
  color: white !important;
}
::v-deep(.v-text-field--outlined fieldset) {
  color: white !important;
}
.dotted-divider {
  border-top: 1px dotted white !important;
  opacity: 1;
}
</style>
