<template>
  <div class="bt-home pb-5">
    <div class="d-flex backCustomSatu" style="height: 420px; border-radius: 0 0 50px 50px"></div>
    <div style="margin-top: -430px"></div>
    <div class="mx-5 d-flex justify-center align-center">
      <h6 class="text-subtitle-1 font-weight-bold" style="color: rgba(255, 255, 255, 0.7); z-index: 1">{{ this.user.branch_name }}</h6>
    </div>
    <JadwalSholat />
    <div style="margin-top: 10px"></div>
    <v-col class="pa-5" cols="12">
      <v-btn @click="startDownload" height="50" width="100%" class="rounded-pill white--text font-weight-bold text-subtitle-1 neumorphic-btn bg-gradient transition-all relative overflow-hidden">
        <v-icon left class="text-h5 animate-bounce">mdi-file-download</v-icon>
        <h5>ambil Data</h5>
      </v-btn>
      <v-dialog v-model="isDialogVisible" persistent max-width="400">
        <v-card class="pa-3 rounded-xl">
          <v-card-title class="headline d-flex justify-center align-center">
            <v-icon color="grey" class="text-h2" v-if="progress < 100">mdi-cloud-download-outline</v-icon> <v-icon color="grey" class="text-h2" v-else>mdi-cloud-check-variant</v-icon></v-card-title
          >
          <v-card-text>
            <p class="d-flex justify-center align-center" v-if="progress < 100">Mohon tunggu, Sedang mengambil data...</p>
            <p class="d-flex justify-center align-center" v-else>Data berhasil diambil</p>

            <v-progress-linear v-if="isLoading" color="var(--primary)" :value="progress" height="20" striped rounded>
              <template v-slot:default="{ value }">
                <strong class="py-2" style="color: white !important">{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </v-card-text>

          <v-card-actions class="justify-end">
            <v-btn class="rounded-lg green--text" small v-if="progress >= 100" @click="handleSelesai"><v-icon small class="mr-2">mdi-check-all</v-icon> selesai </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
    <div class="mb-5 d-flex flex-column align-center">
      <div class="px-5">
        <v-row class="mt-4 pt-4">
          <!-- Saldo Awal -->
          <v-col cols="6">
            <div class="d-flex justify-end align-center">
              <div class="pa-3 rounded-lg elevation-1" style="width: 100%; background-color: #672178">
                <div class="pb-0">
                  <v-icon role="img" class="align-center rounded-lg pa-3" dense style="margin-top: -50px; background-color: white; color: #672178"> mdi-wallet </v-icon>
                </div>
                <div>
                  <span class="text-body-2 font-weight-bold white--text" style="position: relative">Saldo Awal</span>
                </div>
                <div class="text-body-2 font-weight-bold white--text" style="position: relative">Rp {{ thousand(kasPetugas.saldoAwal) }}</div>
              </div>
            </div>
          </v-col>
          <!-- End Saldo Awal -->

          <!-- Saldo Akhir -->
          <v-col cols="6">
            <div class="d-flex justify-end align-center">
              <div class="pa-3 rounded-lg elevation-1" style="width: 100%; background-color: #ff9b6a">
                <div class="pb-0">
                  <v-icon role="img" class="align-center rounded-lg pa-3" dense style="margin-top: -50px; background-color: white; color: #ff9b6a"> mdi-wallet-bifold </v-icon>
                </div>
                <div>
                  <span class="text-body-2 font-weight-bold white--text" style="position: relative">Saldo Akhir</span>
                </div>
                <div class="text-body-2 font-weight-bold white--text" style="position: relative">Rp {{ thousand(kasPetugas.saldoAkhir) }}</div>
              </div>
            </div>
          </v-col>
          <!-- End Saldo Akhir -->

          <!-- Kas Masuk -->
          <v-col cols="6" class="mt-3">
            <div class="d-flex justify-end align-center">
              <div class="pa-3 rounded-lg elevation-1" style="width: 100%; background-color: #78b3ce">
                <div class="pb-0">
                  <v-icon role="img" class="align-center rounded-lg pa-3" dense style="margin-top: -50px; background-color: white; color: #78b3ce"> mdi-cash-plus </v-icon>
                </div>
                <div>
                  <span class="text-body-2 font-weight-bold white--text" style="position: relative">Kas Masuk</span>
                </div>
                <div class="text-body-2 font-weight-bold white--text" style="position: relative">Rp {{ thousand(kasPetugas.kasMasuk) }}</div>
              </div>
            </div>
          </v-col>
          <!-- End Kas Masuk -->

          <!-- Kas Keluar -->
          <v-col cols="6" class="mt-3">
            <div class="d-flex justify-end align-center">
              <div class="pa-3 rounded-lg elevation-1" style="width: 100%; background-color: #1f509a">
                <div class="pb-0">
                  <v-icon role="img" class="align-center rounded-lg pa-3" dense style="margin-top: -50px; background-color: white; color: #1f509a"> mdi-cash-minus </v-icon>
                </div>
                <div>
                  <span class="text-body-2 font-weight-bold white--text" style="position: relative">Kas Keluar</span>
                </div>
                <div class="text-body-2 font-weight-bold white--text" style="position: relative">Rp {{ thousand(kasPetugas.kasKeluar) }}</div>
              </div>
            </div>
          </v-col>
          <!-- End Kas Keluar -->
        </v-row>
        <v-row>
          <!-- PEMBIAYAAN -->
          <v-col cols="12">
            <v-card
              class="pa-5 rounded-lg"
              :style="{
                backgroundColor: $vuetify.theme.dark ? 'var(--dark-bg)' : 'var(--light-bg)',
              }"
              style="border: 1px solid #004182"
            >
              <div class="d-flex">
                <div>
                  <v-icon class="align-center pa-2 rounded-circle" style="background-color: #add6ff; color: #004182">mdi-wallet-bifold</v-icon>
                </div>
                <div class="ml-4 d-flex justify-center align-center">
                  <span class="font-weight-bold align-center"> Pembiayaan </span>
                </div>
              </div>
              <v-divider class="my-4" />
              <div class="d-flex align-center rounded-lg mt-2 px-2 py-0 bg-grey position-relative">
                <v-col cols="6" class="d-flex align-center">
                  <div class="mr-4">
                    <v-icon color="green darken-2" class="align-center" large> mdi-receipt-text-edit </v-icon>
                  </div>
                  <div>
                    <div class="font-weight-bold">{{ this.pembiayaan.pengajuan }}</div>
                    <div class="text-caption text--secondary">Pengajuan</div>
                  </div>
                </v-col>
                <div class="separator"></div>
                <v-col cols="6" class="d-flex align-center">
                  <div class="mr-4">
                    <v-icon color="red darken-2" class="align-center" large> mdi-receipt-text-send </v-icon>
                  </div>
                  <div>
                    <div class="font-weight-bold">{{ this.pembiayaan.pencairan }}</div>
                    <div class="text-caption text--secondary">Pencairan</div>
                  </div>
                </v-col>
              </div>
            </v-card>
          </v-col>
          <!-- END PEMBIAYAAN -->

          <!-- TABUNGAN -->
          <v-col cols="12">
            <v-card
              class="pa-5 rounded-lg"
              :style="{
                backgroundColor: $vuetify.theme.dark ? 'var(--dark-bg)' : 'var(--light-bg)',
              }"
              style="border: 1px solid #ff9b6a"
            >
              <div class="d-flex">
                <div>
                  <v-icon class="align-center pa-2 rounded-circle" style="background-color: #ffd8c4; color: #ff9b6a">mdi-hand-coin</v-icon>
                </div>
                <div class="ml-4 d-flex justify-center align-center">
                  <span class="font-weight-bold align-center"> Tabungan </span>
                </div>
              </div>
              <v-divider class="my-4" />
              <div class="d-flex align-center rounded-lg mt-2 px-2 py-0 bg-grey position-relative">
                <v-col cols="6" class="d-flex align-center">
                  <div class="mr-4">
                    <v-icon color="green darken-2" class="align-center" large> mdi-cash-plus </v-icon>
                  </div>
                  <div>
                    <div class="font-weight-bold">{{ this.tabungan.pembukaan }}</div>
                    <div class="text-caption text--secondary">Pembukaan</div>
                  </div>
                </v-col>
                <div class="separator"></div>
                <v-col cols="6" class="d-flex align-center">
                  <div class="mr-4">
                    <v-icon color="red darken-2" class="align-center" large> mdi-transfer </v-icon>
                  </div>
                  <div>
                    <div class="font-weight-bold">{{ this.tabungan.pencairan }}</div>
                    <div class="text-caption text--secondary">Pencairan</div>
                  </div>
                </v-col>
              </div>
            </v-card>
          </v-col>
          <!-- END TABUNGAN -->

          <!-- ANGGOTA -->
          <v-col cols="12">
            <v-card
              class="pa-5 rounded-lg"
              :style="{
                backgroundColor: $vuetify.theme.dark ? 'var(--dark-bg)' : 'var(--light-bg)',
              }"
              style="border: 1px solid #5dae8b"
            >
              <div class="d-flex">
                <div>
                  <v-icon class="align-center pa-2 rounded-circle" style="background-color: #ceffea; color: #5dae8b">mdi-account-supervisor-circle</v-icon>
                </div>
                <div class="ml-4 d-flex justify-center align-center">
                  <span class="font-weight-bold align-center"> Anggota </span>
                </div>
              </div>
              <v-divider class="my-4" />
              <div class="d-flex align-center rounded-lg mt-2 px-2 py-0 bg-grey position-relative">
                <v-col cols="6" class="d-flex align-center">
                  <div class="mr-4">
                    <v-icon color="green darken-2" class="align-center" large> mdi-arrow-up-right </v-icon>
                  </div>
                  <div>
                    <div class="font-weight-bold">{{ this.anggota.gabung }}</div>
                    <div class="text-caption text--secondary">Gabung</div>
                  </div>
                </v-col>
                <div class="separator"></div>
                <v-col cols="6" class="d-flex align-center">
                  <div class="mr-4">
                    <v-icon color="red darken-2" class="align-center" large> mdi-arrow-down-left </v-icon>
                  </div>
                  <div>
                    <div class="font-weight-bold">{{ this.anggota.keluar }}</div>
                    <div class="text-caption text--secondary">Keluar</div>
                  </div>
                </v-col>
              </div>
            </v-card>
          </v-col>
          <!-- END ANGGOTA -->
        </v-row>
      </div>
    </div>
    <v-snackbar v-model="alert.show" :timeout="5000">
      {{ alert.msg }}
    </v-snackbar>
  </div>
</template>

<script>
import Toast from "@/components/Toast";
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
import helper from "@/utils/helper";
import JadwalSholat from "../components/JadwalSholat/JadwalSholat.vue";
import { getData, saveData } from "@/utils/indexedDB";
import { downloadAndSaveData } from "@/utils/indexedDB";
export default {
  name: "Home",
  components: {
    Toast,
    JadwalSholat,
  },
  data() {
    return {
      form: {
        data: {
          latitude: 0,
          longitude: 0,
        },
      },
      kasPetugas: {
        saldoAwal: 0,
        saldoAkhir: 0,
        kasMasuk: 0,
        kasKeluar: 0,
      },
      pembiayaan: {
        pengajuan: 0,
        pencairan: 0,
      },
      tabungan: {
        pembukaan: 0,
        pencairan: 0,
      },
      anggota: {
        gabung: 0,
        keluar: 0,
      },
      alert: {
        show: false,
        msg: "",
      },
      progress: 0,
      isLoading: false,
      errorMessage: "",
      isDialogVisible: false,
    };
  },
  computed: {
    ...mapGetters(["user", "userRole"]),
  },

  methods: {
    ...helper,
    ...mapActions(["logout"]),
    async cekDataIndexedDB() {
      const saldo = await getData("kas_petugas", "kas_petugas");
      if (!saldo) {
        this.showAlert("Belum ada data tersimpan. Silakan download data terlebih dahulu!", "warning");
        return false;
      }
      return true;
    },
    startDownload() {
      this.isLoading = true;
      this.isDialogVisible = true;
      this.progress = 0;
      this.errorMessage = "";

      downloadAndSaveData(
        this.user,
        (progressBar) => {
          this.$nextTick(() => {
            this.progress = progressBar * 100;
          });
        },
        (error) => {
          console.log("Gagal menyinkronkan data: " + error);
          this.errorMessage = "Gagal menyinkronkan data: " + error;
          this.isLoading = false;
        }
      );
    },

    async getSaldo() {
      try {
        const saldo = await getData("kas_petugas", "kas_petugas");
        const req = saldo?.data?.data;

        if (req?.status === true) {
          this.kasPetugas.saldoAwal = parseFloat(req.data?.saldo_awal || 0);
          this.kasPetugas.saldoAkhir = parseFloat(req.data?.saldo_akhir || 0);
          this.kasPetugas.kasMasuk = parseFloat(req.data?.kas_masuk || 0);
          this.kasPetugas.kasKeluar = parseFloat(req.data?.kas_keluar || 0);
        } else {
          const errorMsg = req?.data?.msg ?? "Unknown error";
          if (errorMsg.toLowerCase().includes("token")) {
            this.logout();
            await this.$router.push("/login");
            return;
          }
        }
      } catch (error) {
        console.error("Gagal mengambil saldo:", error);
      }
    },
    async getCountPembiayaan() {
      try {
        const count_pembiayaan = await getData("count_pembiayaan", "count_pembiayaan");
        const req = count_pembiayaan?.data?.data;
        if (req?.status === true) {
          this.pembiayaan.pengajuan = parseFloat(req.data?.pengajuan || 0);
          this.pembiayaan.pencairan = parseFloat(req.data?.pencairan || 0);
        } else {
          const errorMsg = req?.data?.msg ?? "Unknown error";
          if (errorMsg.toLowerCase().includes("token")) {
            this.logout();
            await this.$router.push("/login");
            return;
          }
        }
      } catch (error) {
        console.error("Gagal mengambil saldo:", error);
      }
    },
    async getCountTabungan() {
      try {
        const count_tabungan = await getData("count_tabungan", "count_tabungan");
        const req = count_tabungan?.data?.data;
        if (req?.status === true) {
          this.tabungan.pengajuan = parseFloat(req.data?.pembukaan || 0);
          this.tabungan.pencairan = parseFloat(req.data?.pencairan || 0);
        } else {
          const errorMsg = req?.data?.msg ?? "Unknown error";
          if (errorMsg.toLowerCase().includes("token")) {
            this.logout();
            await this.$router.push("/login");
            return;
          }
        }
      } catch (error) {
        console.error("Gagal mengambil saldo:", error);
      }
    },
    async getCountAnggota() {
      try {
        const count_anggota = await getData("count_anggota", "count_anggota");
        const req = count_anggota?.data?.data;
        if (req?.status === true) {
          this.anggota.pengajuan = parseFloat(req.data?.anggota_masuk || 0);
          this.anggota.pencairan = parseFloat(req.data?.anggota_keluar || 0);
        } else {
          const errorMsg = req?.msg ?? "Unknown error";
          if (errorMsg.toLowerCase().includes("token")) {
            this.logout();
            await this.$router.push("/login");
            return;
          }
        }
      } catch (error) {
        console.error("Gagal mengambil saldo:", error);
      }
    },
    handleSelesai() {
      this.isDialogVisible = false; // Tutup dialog

      this.getSaldo();
      this.getCountPembiayaan();
      this.getCountTabungan();
      this.getCountAnggota();
    },

    getDate() {
      let today = new Date();
      let day = today.getDate();
      let month = today.getMonth() + 1;
      let year = today.getFullYear();
      return `${year}-${month}-${day}`;
    },

    // DETEKSI GPS DAN UPDATE KE LOCALSTORAGE
    async geoLocation() {
      if (!navigator.geolocation) {
        this.showAlert("⚠️ Maaf, Browser Anda Tidak Mendukung", "warning");
        return;
      }

      try {
        const position = await this.getCurrentPosition();
        this.updateLocation(position);
      } catch (error) {
        this.handleGeoError(error);
      }

      this.watchId = navigator.geolocation.watchPosition(this.updateLocation, this.handleGeoError, { enableHighAccuracy: true });
    },

    async getCurrentPosition() {
      return new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          timeout: 3000,
          maximumAge: 0,
        });
      });
    },

    updateLocation(position) {
      this.form.data.latitude = position.coords.latitude;
      this.form.data.longitude = position.coords.longitude;

      // Simpan ke localStorage sebagai fallback
      localStorage.setItem(
        "lastLocation",
        JSON.stringify({
          latitude: this.form.data.latitude,
          longitude: this.form.data.longitude,
        })
      );
    },

    handleGeoError(error) {
      let msg = "Gagal mendapatkan lokasi.";
      switch (error.code) {
        case error.PERMISSION_DENIED:
          msg = "Anda menolak akses lokasi. Silakan aktifkan izin lokasi di pengaturan.";
          if (this.watchId) {
            navigator.geolocation.clearWatch(this.watchId);
          }
          break;

        case error.POSITION_UNAVAILABLE:
          msg = "Lokasi tidak tersedia. Pastikan GPS Anda aktif.";
          break;

        case error.TIMEOUT:
          msg = "Pengambilan lokasi terlalu lama. Menggunakan lokasi terakhir yang tersedia.";
          const lastLocation = localStorage.getItem("lastLocation");
          if (lastLocation) {
            const parsed = JSON.parse(lastLocation);
            this.updateLocation({ coords: parsed });
            return;
          }
          break;
      }
      this.$store.dispatch("showAlert", { show: false });
      setTimeout(() => {
        this.$store.dispatch("showAlert", {
          show: true,
          msg: "⚠️" + " " + msg,
          color: "warning",
          position: "top",
        });
      }, 3000);

      if (!navigator.onLine) {
        getLastLocation();
        return;
      }

      navigator.geolocation.getCurrentPosition(this.updateLocation, this.handleGeoError, {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      });

      if (!this.watchId) {
        this.watchId = navigator.geolocation.watchPosition(this.updateLocation, this.handleGeoError, {
          enableHighAccuracy: true,
        });
      }
    },
    // END DETEKSI GPS

    // UPDATE KE LOCALSTORAGE
    async updateSaldoFromServer() {
      try {
        let payload = new FormData();
        payload.append("account_cash_code", this.user.account_cash_code);
        const req = await services.infoSaldoPetugas(payload, this.user.token);
        const response = req.data;
        if (response?.status === true) {
          await saveData("kas_petugas", req, "kas_petugas");
          this.getSaldo();
        }
      } catch (error) {
        console.error("Gagal update saldo dari server:", error);
      }
    },
    async updateCountPembiayaanFromServer() {
      try {
        let payload = new FormData();
        payload.append("fa_code", this.user.fa_code);
        const req = await services.infoFinancing(payload, this.user.token);
        const response = req.data;
        if (response?.status === true) {
          await saveData("count_pembiayaan", req, "count_pembiayaan");
          this.getSaldo();
        }
      } catch (error) {
        console.error("Gagal update count pembiayaan dari server:", error);
      }
    },
    async updateCountPembiayaanFromServer() {
      try {
        let payload = new FormData();
        payload.append("fa_code", this.user.fa_code);
        const req = await services.infoFinancing(payload, this.user.token);
        const response = req.data;
        if (response?.status === true) {
          await saveData("count_pembiayaan", req, "count_pembiayaan");
          this.getCountPembiayaan();
        }
      } catch (error) {
        console.error("Gagal update count pembiayaan dari server:", error);
      }
    },
    async updateCountTabunganFromServer() {
      try {
        let payload = new FormData();
        payload.append("fa_code", this.user.fa_code);
        const req = await services.infoSaving(payload, this.user.token);
        const response = req.data;
        if (response?.status === true) {
          await saveData("count_tabungan", req, "count_tabungan");
          this.getCountTabungan();
        }
      } catch (error) {
        console.error("Gagal update count tabungan dari server:", error);
      }
    },
    async updateCountAnggotaFromServer() {
      try {
        let payload = new FormData();
        payload.append("account_cash_code", this.user.account_cash_code);
        const req = await services.infoSaldoPetugas(payload, this.user.token);
        const response = req.data;
        if (response?.status === true) {
          await saveData("count_anggota", req, "count_anggota");
          this.getCountAnggota();
        }
      } catch (error) {
        console.error("Gagal update count anggota dari server:", error);
      }
    },

    async updateAllData() {
      if (navigator.onLine) {
        this.updateSaldoFromServer();
        this.updateCountPembiayaanFromServer();
        this.updateCountTabunganFromServer();
        this.updateCountAnggotaFromServer();
      }
    },

    handleOnline() {
      this.updateAllData();
    },
    // END UPDATE KE LOCALSTORAGE

    showAlert(message, color) {
      this.$store.dispatch("showAlert", { show: false });
      setTimeout(() => {
        this.$store.dispatch("showAlert", {
          show: true,
          msg: message,
          color,
          position: "top",
        });
      }, 3000);
    },
  },

  async created() {
    const hasData = await this.cekDataIndexedDB();
    if (!hasData) return this.showAlert("⚠️ Data belum tersedia. Silakan unduh terlebih dahulu.", "warning");

    this.updateAllData();
    await this.geoLocation();
  },
  mounted() {
    this.onlineHandler = this.handleOnline;
    window.addEventListener("online", this.onlineHandler);
  },

  beforeDestroy() {
    window.removeEventListener("online", this.onlineHandler);
  },
};
</script>
<style scoped>
.bg-gradient {
  background-size: 180% 180%;
  background-image: linear-gradient(145deg, rgba(103, 33, 120, 0.9), rgba(103, 33, 120, 0.7), rgba(103, 33, 120, 0.5), rgba(103, 33, 120, 0.3));
  /* animation: gradientMove 10s ease-in-out infinite; */
}

.neumorphic-btn {
  border-radius: 12px;
  box-shadow: inset 6px 6px 12px rgba(0, 0, 0, 0.3), inset -6px -6px 12px rgba(255, 255, 255, 0.1);
}

.neumorphic-btn:active {
  box-shadow: inset 8px 8px 16px rgba(0, 0, 0, 0.4), inset -8px -8px 16px rgba(255, 255, 255, 0.2);
  transform: scale(0.98);
}
@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 50% 75%;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 25%;
  }
  100% {
    background-position: 0% 50%;
  }
}
</style>
